import { amazon } from "@/i18n/en-us/components/salesChannelManagementApp/amazon";
import { product } from "@/i18n/en-us/components/salesChannelManagementApp/product";
import { prices } from "@/i18n/en-us/components/salesChannelManagementApp/prices";
import { tracking } from "@/i18n/en-us/components/salesChannelManagementApp/tracking";
import { shopify } from "@/i18n/en-us/components/salesChannelManagementApp/shopify";
import { orders } from "@/i18n/en-us/components/salesChannelManagementApp/orders";
import { stock } from "@/i18n/en-us/components/salesChannelManagementApp/stock";
import { settings } from "@/i18n/en-us/components/salesChannelManagementApp/settings";
import { woocommerce } from "@/i18n/en-us/components/salesChannelManagementApp/woocommerce";
import { category } from "@/i18n/en-us/components/salesChannelManagementApp/category";
import { migration } from "@/i18n/en-us/components/salesChannelManagementApp/migration";
import { test } from "@/i18n/en-us/components/salesChannelManagementApp/test";
import { bigcommerce } from "@/i18n/en-us/components/salesChannelManagementApp/bigcommerce";
import { reporting } from "@/i18n/en-us/components/salesChannelManagementApp/reporting";
import { productSelection } from "@/i18n/en-us/components/salesChannelManagementApp/productSelection";
import { shopware6 } from "@/i18n/en-us/components/salesChannelManagementApp/shopware6";
import { otto } from "@/i18n/en-us/components/salesChannelManagementApp/otto";
import { journal } from "@/i18n/en-us/components/salesChannelManagementApp/journal";
import { offer } from "@/i18n/en-us/components/salesChannelManagementApp/offer";

export const salesChannelManagementApp = {
  active: "Active",
  activeHelp: "Is the workflow active?",
  addIntegration: "New integration",
  advancedPricing: "Advanced price setting",
  all: "All",
  amazon: amazon,
  apiKey: "API Key",
  apiKeyHelp: "Authentication data 1",
  appSaved: "The integration {integrationName} has been saved.",
  articleCount: "Article count",
  assignment: "Category assignment",
  assignmentSelectManual: "Manual assignment table",
  assignmentStrategyManual: "Manual assignment table",
  assignmentTableShop: "{appName}",
  assignmentTableXentral: "Xentral",
  authRetry: "Re-authenticate",
  authorize: "Authorize",
  autoAcceptOrders: "Accept orders automatically",
  autoAcceptOrdersHelp:
    "Here you can specify whether new orders in the marketplace should be accepted automatically.",
  back: "Back",
  backToOverview: "Overview",
  baseData: "Master data (excl. prices and stocks)",
  baseSettings: "Base settings",
  bigcommerce: bigcommerce,
  calculateStock: "Calculate stock",
  cancellation: "Cancellation",
  carrier: "Carrier",
  carrierAssignment: "Carrier assignment",
  category: category,
  categoryAssignment: "Category assignment",
  categoryLabel: "Category",
  changeActiveStatusTextActive:
    "Attention! If you activate your integration, data is automatically synchronized with your store. This change will not take effect until the next save.",
  changeActiveStatusTextInactive:
    "Attention! If you activate your integration, data is not automatically synchronized with your store anymore. This change will not take effect until the next save.",
  changeActiveStatusTitle: "Change integration mode?",
  collection: "Collection",
  country: "Country",
  countryAssignment: "Country assignment",
  credentials: "Access data",
  currency: "Currency",
  currencyAssignment: "Currency assignment",
  customField: "Free field",
  customFieldAssignment: "Free field assignment",
  customFields: "Free fields",
  customer: "Customer assignment",
  customerGroup: "customer group",
  customers: "Customer assignment",
  dataTypes: "Data types",
  dataTypesHelp: "Which data types should be transferred?",
  date: "Date",
  deltaTransmission: "Delta transmission",
  deltaTransmissionHelp: "Which items should be transferred?",
  descriptions: {
    baseData: "Add here the base data of your new integration.",
    credentials: "Connect the systems.",
    features: "Activate your features."
  },
  discountProduct: "Discounts",
  discounts: "Credit notes & discounts",
  exportActive:
    "Your integration is being prepared. Please be patient. As soon as the integration is ready, you can configure it here.",
  exportRules: "Export rules",
  featureCategory: "Match categories (Xentral > sales channel)",
  featureOffer:
    "Synchronize offers (incl. prices and stocks) (Xentral > sales channel)",
  featureOrders: "Synchronize orders (sales channel > Xentral)",
  featurePrices: "Compare prices (Xentral > sales channel)",
  featureProduct:
    "Synchronize articles (excl. prices and stocks) (Xentral > sales channel)",
  featureRequired: "Please select at least one feature",
  featureStock: "Synchronize stocks (Xentral > sales channel)",
  featureTracking:
    "Synchronize order statuses and tracking (Xentral > sales channel)",
  features: "Features",
  group: "Product group",
  help: "<div>Having trouble?</div><div>Email us at <a href='mailto:feedback@xentral.com'>feedback@xentral.com</a></div>",
  info: "Note",
  initArticleImport: "Initial article import",
  initialFile: {
    generateInitialFile: "Initiale Datei erzeugen",
    dialogTitle: "Initiale Datei",
    dialogInfo:
      "An import file is required for the initial mapping of your product data in Hornbach/Mirakl. Make sure that it contains enough articles so that all assigned article categories are included at least once.",
    building: "The initial import file is created",
    buildingInfo:
      "As soon as the initial import file has been created, you can download it via the link shown above.",
    limit: "Limit",
    all: "All"
  },
  initialFileCreatedAt: "Last created at",
  initialFileName: "Link to the file",
  initialImportFile: "Initial importfile",
  integration: {
    dev: "This integration is in development mode.",
    live: "This integration is in production mode"
  },
  integrationActiveHelp: "Is this integration active?",
  journal: journal,
  key: "Consumer Key",
  keyword: "Keyword",
  language: "Language",
  languageAssignment: "Language assignment",
  layout: "Layout of the product page",
  lineItems: "Order items",
  liveDevMode: "Integration mode",
  livemode: "Production mode",
  livemodeActive: "Activate production mode",
  livemodeError: "Activation failed. Fill in all marked mandatory fields.",
  loadIntegration:
    "Your integration will be loaded. This process may take some time.",
  mail: "Mail dispatch",
  manual: "Manual",
  marketPlace: "Market place",
  marketPlacePlural: "Market places",
  media: "Media",
  metaTexts: "Meta texts",
  migration: migration,
  mode: "Mode",
  modeHelp: "Is the integration being used live?",
  name: "Name of the integration",
  nameHelp:
    "What should the new integration be named? Please note that the integration name must be unique.",
  next: "Next",
  no: "No",
  noConfigAvailable: "No configuration available",
  notification: "Notifications",
  of: "of",
  offer: offer,
  onChange: "On change",
  orders: orders,
  otto: otto,
  password: "Password / API-Key",
  passwordHelp: "Authentication data 2",
  payment: "Payment methods",
  paymentAndShippingState: "Payment and shipping status",
  paymentMethod: "Payment method",
  paymentMethods: "Payment methods",
  paymentState: "Payment status",
  pos: "POS customers",
  position: "Positions",
  priceRule: "Price rules",
  priceSelection: "Price selection",
  prices: prices,
  product: product,
  productAssignment: "Product assignment",
  productAssignmentTarget: "Field from {appName}",
  productBaseData: "Master data",
  productSelection: productSelection,
  productSelectionTable: {
    id: "ID",
    name: "Name",
    productNumber: "Article no.",
    ean: "EAN no. / barcode",
    isDisabled: "Lock active",
    projectId: "Project ID",
    merchandiseGroupId: "Article category ID"
  },
  project: "Project",
  projectHelp: "Which project in Xentral is the integration assigned to?",
  properties: "Properties",
  publication: "Sales Channel",
  remark: "Comments",
  reporting: reporting,
  returns: "Returns",
  salesChannelAssignment: "Project assignment",
  salesChannels: "Sales channels",
  save: "Save",
  saveIntegration:
    "Your integration will be created. This process may take some time.",
  secret: "Consumer Secret",
  settings: settings,
  shipFromAddress: "Warehouse address for order status and tracking feedback",
  shipping: "Shipping methods & delivery terms",
  shippingCosts: "Shipping costs",
  shippingCostsDiscountsTaxes: "Shipping costs, discounts and taxes",
  shippingMethod: "Shipping method",
  shippingMethods: "Shipping methods",
  shippingProduct: "Shipping costs",
  shippingState: "Shipping status",
  shop: "Online shop",
  shopPlural: "Online shops",
  shopify: shopify,
  shopware6: shopware6,
  stateOfTransfer: "Status for transfer",
  steps: "Step",
  stock: stock,
  surchargeProduct: "Surcharges",
  taxes: "Taxes",
  test: test,
  timeBased: "Time-based",
  timeOfTransfer: "Time of transfer",
  timeOfTransferHelp: "How and when is the transfer triggered?",
  titles: {
    additionalSettings: "Further settings",
    baseData: "Base data",
    baseSettings: "Base settings",
    category: "Category",
    credentials: "Access data",
    dashboard: "Dashboard",
    features: "Features",
    journal: "Products",
    prices: "Prices",
    product: "Product",
    tbOneChannelSelection: "TB.One Channel",
    productSelection: {
      selection: "Product selection",
      configure: "Configure filter"
    },
    salesOrder: "Orders",
    salesOrder_payment: "Payment methods",
    salesOrder_shipping: "Shipping methods",
    settings: {
      title: "Settings",
      wizard: "Basic settings",
      productAssignment: "Article assignment",
      productSelection: "Article selection"
    },
    title: {
      stock: "Bestand",
      price: "Preise",
      initialImportFile: "Initiale Importdatei",
      general: "Allgemein"
    },
    stock: "Stock",
    tracking: "Order status & tracking",
    migration: {
      title: "Initial data import",
      strategy: "Migration strategy",
      settings: "Settings"
    },
    sftpData: "SFTP-Data"
  },
  token: "Token",
  tracking: tracking,
  updateStrategy: "Update strategy",
  updateStrategyHelp: "How and when is the transfer triggered?",
  url: "URL",
  urlHelp: "URL of the system to be connected",
  useFilter: "Use filter",
  usedFilters: "Filters used so far",
  username: "Username",
  validateCredentials: "Test connection",
  validateSftpData: "Test sftp data",
  validateCredentialsError:
    "Please check that correct credentials have been entered",
  validateCredentialsSuccess: "The connection could be established.",
  value: "Value",
  warehouse: "Warehouse",
  wizardError: "There was an error creating the integration.",
  wizardSaving: "Your new store integration is being created...",
  wizardSuccess: "The integration {integrationName} was successfully created.",
  wizardSalesChannelNotCreated:
    "The integration {integrationName} was created. However, no suitable sales channel could be created. Please contact support.",
  woocommerce: woocommerce,
  workflows: "Workflows",
  xentral: "Xentral",
  yes: "Yes"
};
