const parentApp = {
  id: 9,
  name: "mirakl",
  areaBlacklist: ["migration"],
  additional_params: {
    requestHeaders: {
      Authorization: "{{key}}",
      Accept: "application/json"
    },
    requestMethod: "GET",
    endpoint: "/api/account"
  },
  isMarketplace: true,
  environmentVariables: {
    presetId: process.env?.VUE_APP_SCM_APP_MIRAKL_PRESET_ID,
    xentralWorkflowId:
      process.env.VUE_APP_SCM_APP_MIRAKL_REPORTING_XENTRAL_WORKFLOW_ID,
    CFLWorkflowId: process.env.VUE_APP_SCM_APP_MIRAKL_REPORTING_CFL_WORKFLOW_ID,
    CFLDataSetId: process.env.VUE_APP_SCM_APP_MIRAKL_REPORTING_CFL_DATASET_ID,
    product: {
      initialWorkflowId:
        process.env?.VUE_APP_SCM_APP_MIRAKL_INITIAL_PRODUCT_WORKFLOW_ID
    },
    testBatchWorkflows: {
      salesOrder:
        process.env?.VUE_APP_SCM_APP_MIRAKL_TEST_BATCH_ORDER_WORKFLOW_ID,
      tracking: process.env?.VUE_APP_SCM_APP_TEST_BATCH_TRACKING_WORKFLOW_ID
    },
    syncBatchWorkflowId:
      process.env.VUE_APP_SCM_APP_MIRAKL_REPORTING_SYNC_BATCH_WORKFLOW_ID
  }
};

const apps = [
  {
    name: "hornbach",
    label: "Hornbach",
    logo: process.env.BASE_URL + "media/shopLogos/mirakl_hornbach.svg"
  },
  {
    name: "shopapotheke",
    label: "Shop Apotheke",
    logo: process.env.BASE_URL + "media/shopLogos/mirakl_shopapotheke.svg"
  },
  {
    name: "mediamarkt",
    label: "MediaMarkt",
    logo: process.env.BASE_URL + "media/shopLogos/mirakl_mediamarkt.svg"
  },
  {
    name: "conrad",
    label: "Conrad",
    logo: process.env.BASE_URL + "media/shopLogos/mirakl_conrad.svg"
  },
  {
    name: "decathlon",
    label: "Decathlon",
    logo: process.env.BASE_URL + "media/shopLogos/mirakl_decathlon.svg"
  },
  {
    name: "voelkner",
    label: "Voelkner",
    logo: process.env.BASE_URL + "media/shopLogos/mirakl_voelkner.webp"
  },
  {
    name: "douglas",
    label: "Douglas",
    logo: process.env.BASE_URL + "media/shopLogos/mirakl_douglas.svg"
  },
  {
    name: "fressnapf",
    label: "Fressnapf",
    logo: process.env.BASE_URL + "media/shopLogos/mirakl_fressnapf.svg"
  },
  {
    name: "home24",
    label: "Home24",
    logo: process.env.BASE_URL + "media/shopLogos/mirakl_home24.svg"
  }
];

const childApps = apps.map((child, index) => ({
  id: parentApp.id + "-" + index,
  name: child.name,
  label: child.label,
  logo: child.logo,
  parentName: parentApp.name
}));

export const miraklApps = [parentApp, ...childApps];
