import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import Auth from "@/components/General/Auth/auth";
import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";

import VueClipboard from "vue-clipboard2";

VueClipboard.config.autoSetContainer = true;
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;

import VueHighlightJS from "vue-highlight.js";
import "vue-highlight.js/lib/allLanguages";
import "highlight.js/styles/default.css";

Vue.use(VueHighlightJS);

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor);

import Quill from "quill";
import ImageResize from "quill-image-resize-vue";

Quill.register("modules/imageResize", ImageResize);

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

Vue.component("VueJsonPretty", VueJsonPretty);

import VueEasyLightbox from "vue-easy-lightbox";

Vue.use(VueEasyLightbox);

import draggable from "vuedraggable";

Vue.component("Draggable", draggable);

import slVueTree from "sl-vue-tree";
import "sl-vue-tree/dist/sl-vue-tree-minimal.css";

Vue.component("SlVueTree", slVueTree);

import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

import VueResizable from "vue-resizable";

Vue.component("VueResizable", VueResizable);

import InlineSvgComponent from "vue-inline-svg";
Vue.component("inline-svg", InlineSvgComponent);

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import Vuelidate from "vuelidate";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/formvalidation";
import "@/core/plugins/swal";
import { Toast } from "@/core/plugins/swal";
import {
  CLEAR_STATE,
  SET_CURRENT_ROUTE
} from "@/core/services/store/route.module";
import menu from "@/core/config/menu.json";

ApiService.init();

window.lastMouseEvent = undefined;
window.lastKeyEvent = undefined;
window.addEventListener("mousedown", e => {
  window.isMiddleMouseButton = e.button === 1;
});
window.addEventListener("mouseup", e => {
  window.isMiddleMouseButton = e.button === 1;
});
window.addEventListener("keydown", e => {
  window.isCtrlKey = e.code === "ControlLeft" || e.code === "ControlRight";
});
window.addEventListener("keyup", e => {
  window.isCtrlKey = e.ctrlKey;
});

document.addEventListener("visibilitychange", onChangeTab);
document.addEventListener("mozvisibilitychange", onChangeTab);
document.addEventListener("webkitvisibilitychange", onChangeTab);
document.addEventListener("msvisibilitychange", onChangeTab);
window.onpageshow =
  window.onpagehide =
  window.onfocus =
  window.onblur =
    onChangeTab;

function onChangeTab() {
  window.isCtrlKey = false;
}

const presetProjectForbiddenRoutes = [
  "projectWorkflowsCreate",
  "projectMappingsMappingsCreate",
  "projectDataStructuresCreate",
  "projectTextTemplatesCreate",
  "projectDataSetsCreate"
];
router.beforeEach((to, from, next) => {
  if (menu.map(item => item.route).includes(to.name) && from.name !== to.name) {
    store.dispatch("route/" + CLEAR_STATE);
  }

  store.dispatch("route/" + SET_CURRENT_ROUTE, to);

  if (window.isMiddleMouseButton || window.isCtrlKey) {
    window.isMiddleMouseButton = false;
    window.isCtrlKey = false;
    event.preventDefault();
    let routeData = router.resolve(to);
    let newTab = window.open(window.location.origin + "/" + routeData.href);
    newTab.focus();
    return;
  }
  if (
    store.getters.isPresetProject &&
    presetProjectForbiddenRoutes.includes(to.name)
  ) {
    Toast.fire({
      icon: "warning",
      title: i18n.t("general.presetProjectForbiddenRoutes")
    });
    next(from);
    return;
  }
  Promise.all([Auth.verify()]).then(next);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export const bus = new Vue();

Vue.use(VueClipboard);
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
